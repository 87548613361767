import {
  FloorIncidentsDocument,
  FloorPlanDevicesDocument,
} from '@/graphql/generated/hooks'

export const getRefetchQueries = (floorId?: string) => {
  // Applicable for both "normal" incidents and market facility incidents
  const baseRefetchQueries = [
    'Incidents',
    'Facilities',
    'facilityFloorsDrawer',
    'floorIncidents',
    'Organization',
    'TotalIncidentsKPI',
    'TotalActiveIncidentsKPI',
    'GlobalUnresolvedIncidentsCount',
  ]
  // Additional refetch queries not applicable for market facility incidents
  const floorAndDeviceRefetchQueries = [
    'Devices',
    {
      query: FloorIncidentsDocument,
      variables: { floorId },
    },
    {
      query: FloorPlanDevicesDocument,
      variables: { floorId },
    },
  ]

  return floorId
    ? [...baseRefetchQueries, ...floorAndDeviceRefetchQueries]
    : baseRefetchQueries
}
