import { useEffect, useState } from 'react'

import { useIncidentCreationData } from '../hooks/useIncidentCreationData'
import { UseFormMethods } from 'react-hook-form'

import { Box, ModalBody } from '@chakra-ui/react'

import {
  FormInputControl,
  FormInputSelectControl,
  FormTextareaControl,
} from '@/components/ui'
import { FacilityType } from '@/graphql/generated/schemas'
import { getIncidentFacilityOptions } from '@/utils/forms/selectOptions'

import { FormInputsIProps } from '../types/types'
import { MarketIncidentForm } from './MarketIncidentForm'
import { NormalIncidentForm } from './NormalIncidentForm'

interface IncidentModalBodyIProps {
  form: UseFormMethods<FormInputsIProps>
}

export const IncidentModalBody = ({ form }: IncidentModalBodyIProps) => {
  const { watch, register, getValues, reset, control, errors } = form

  const [facility, setFacility] = useState(null)
  const { facilitiesData } = useIncidentCreationData()
  const facilityOptions = getIncidentFacilityOptions(facilitiesData)

  const watchFacilityOrigin = watch('facilityOrigin')
  const shouldShowMarketFlow = facility?.type === FacilityType.Market

  useEffect(() => {
    if (watchFacilityOrigin) {
      const selectedFacility = facilitiesData?.facilities?.edges?.find(
        (f) => f?.node?.id == watchFacilityOrigin?.value
      )
      setFacility(selectedFacility?.node)
      reset({
        ...getValues(),
        floorOrigin: null,
        deviceOrigin: null,
      })
    }
  }, [watchFacilityOrigin])

  return (
    <ModalBody p={0}>
      <Box px={6} py={2}>
        <Box mb={3}>
          <FormInputControl
            data-testid='createIncidentModal_incidentName'
            errorMessage={errors.name && errors.name.message}
            id='name'
            inputRef={register({
              maxLength: {
                value: 255,
                message: 'Incident name should have less than 256 characters',
              },
              required: 'Incident name is required',
            })}
            isInvalid={!!errors.name}
            label='Incident Name'
            placeholder='Enter Incident Name'
          />
        </Box>
        <Box mb={3}>
          <FormInputSelectControl
            control={control}
            dataTestId='createIncidentModal_facilityName'
            defaultValue={null}
            id='facilityOrigin'
            label='Facility Name'
            options={facilityOptions}
            placeholder='Select the Facility of Origin'
            rules={{ required: true }}
          />
        </Box>
        {shouldShowMarketFlow ? (
          <MarketIncidentForm form={form} />
        ) : (
          <NormalIncidentForm facility={facility} form={form} />
        )}
        <Box mb={3}>
          <FormTextareaControl
            data-testid='createIncidentModal_description'
            errorMessage={errors.description && errors.description.message}
            id='description'
            isInvalid={!!errors.description}
            label='Incident Description'
            labelFontWeight='500'
            placeholder='Enter Incident Description'
            textareaRef={register({
              maxLength: {
                value: 255,
                message:
                  'Incident description should have less than 256 characters',
              },
              required: 'Incident description is required',
            })}
          />
        </Box>
      </Box>
    </ModalBody>
  )
}
