import { MAX_CSV_SIZE, MAX_FILENAME_LENGTH, useUpload } from '@/hooks/useUpload'
import { useSelector } from 'react-redux'

import {
  useCreateFloorMutation,
  useCreateFloorPlanMutation,
  useFacilityNormalTypeNamesQuery,
} from '@/graphql/generated/hooks'
import { CreateFloorInput, UploadedFileType } from '@/graphql/generated/schemas'
import { selectMe } from '@/redux/me/meSlice'
import { getFacilityOptions } from '@/utils/forms/selectOptions'

import { removeExtension } from '../utils/utils'

export const useCreateFloor = () => {
  const me = useSelector(selectMe)
  const { data: facilitiesData } = useFacilityNormalTypeNamesQuery({
    fetchPolicy: 'network-only',
  })
  const [createFloor] = useCreateFloorMutation()
  const [createFloorPlan] = useCreateFloorPlanMutation()
  const [upload] = useUpload()

  const facilityOptions = getFacilityOptions(facilitiesData)

  const create = async (input: CreateFloorInput, file: File) => {
    let fileId = null
    if (file) {
      fileId = await upload({
        file,
        fileType: UploadedFileType.FloorPlan,
        validationOptions: {
          maxFileSizeInBytes: MAX_CSV_SIZE,
          maxFileNameLength: MAX_FILENAME_LENGTH,
        },
      })
    }

    // Create Floor
    const { data: floorData } = await createFloor({
      variables: { input },
      refetchQueries: [
        'Facilities',
        'facilityFloorsDrawer',
        'floorsByFacilityIds',
      ],
    })
    if (!file) {
      return
    }

    // Create Floorplan
    const floorId = floorData?.createFloor?.floor?.id
    const name = removeExtension(file.name)
    const s3Value = `${me?.id}/${fileId}`
    await createFloorPlan({
      variables: { input: { floorId, name, s3Key: s3Value } },
      refetchQueries: [
        'Facilities',
        'facilityFloorsDrawer',
        'floorsByFacilityIds',
      ],
    })
  }

  return {
    facilityOptions,
    create,
  }
}
