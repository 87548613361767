import { useState } from 'react'

import { useToasts } from '@/hooks/useToasts'
import { MAX_FILENAME_LENGTH, MAX_PNG_SIZE, useUpload } from '@/hooks/useUpload'
import { SubmitHandler } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  useCreateFloorPlanMutation,
  useUpdateFloorMutation,
} from '@/graphql/generated/hooks'
import { useFloorDetailsQuery } from '@/graphql/generated/hooks'
import { UpdateFloorInput, UploadedFileType } from '@/graphql/generated/schemas'
import { selectMe } from '@/redux/me/meSlice'

import { FloorFormInputsIProps } from '../types/types'
import {
  getErrorMessage,
  getSuccessMessage,
  removeExtension,
} from '../utils/utils'

export const useEditFloor = ({ floorId, onClose }) => {
  const me = useSelector(selectMe)
  const { showSuccess, showError } = useToasts()
  const [isUpdateLoading, setUpdateLoading] = useState(false)

  const [upload] = useUpload()
  const [updateFloor] = useUpdateFloorMutation()
  const [createFloorPlan] = useCreateFloorPlanMutation()

  const { data: floorDetails, loading: isFloorDetailsLoading } =
    useFloorDetailsQuery({
      fetchPolicy: 'network-only',
      variables: { floorId: floorId },
    })

  const uploadFloorPlan = async (
    floorId: string,
    file: File
  ): Promise<void> => {
    const fileId = await upload({
      file,
      fileType: UploadedFileType.FloorPlan,
      validationOptions: {
        maxFileSizeInBytes: MAX_PNG_SIZE,
        maxFileNameLength: MAX_FILENAME_LENGTH,
      },
    })

    // Create FloorPlan
    const name = removeExtension(file.name)
    const s3Value = `${me?.id}/${fileId}`
    await createFloorPlan({
      variables: { input: { floorId, name, s3Key: s3Value } },
    })
  }

  const update = async (input: UpdateFloorInput, file: File) => {
    if (file) {
      await uploadFloorPlan(input.id, file)
    }

    // Update Floor
    const updateFloorInput = {
      id: floorId,
      name: input.name,
    }
    await updateFloor({
      variables: {
        input: updateFloorInput,
      },
      refetchQueries: [
        'facilities',
        'floorsByFacilityIds',
        'facilityFloorsDrawer',
        'floorPlanDetails',
      ],
    })
  }

  const onSubmit: SubmitHandler<FloorFormInputsIProps> = async (values) => {
    setUpdateLoading(true)
    const file = values.file?.item(0)
    const input: UpdateFloorInput = {
      id: floorId,
      name: values.name,
      facilityId: values.facility?.value,
    }
    try {
      await update(input, file)
      showSuccess(getSuccessMessage(input.name, 'update', file))
      onClose()
    } catch (e) {
      showError(getErrorMessage(values.name, 'update', e))
    }
    setUpdateLoading(false)
  }

  return {
    floorDetails,
    isFloorDetailsLoading,
    onSubmit,
    isUpdateLoading,
  }
}
