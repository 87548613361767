import { ManualIncidentCategoriesQuery } from '@/graphql/generated/operations'
import { getIncidentCategoryOptions } from '@/utils/forms/selectOptions'

export const isDeviceHealthSelected = (
  incidentCategoriesData: ManualIncidentCategoriesQuery,
  incidentCategoryId: string
): boolean => {
  const incidentCategories = getIncidentCategoryOptions(incidentCategoriesData)
  return (
    incidentCategories?.find((i) => i.value === incidentCategoryId)?.label ===
    'Device Health'
  )
}
