import { DEFAULT_MASKED_PASSWORD } from '../hooks/useEditCamera'
import { DeepMap, FieldValues } from 'react-hook-form'

import { DeviceCameraEditQuery } from '@/graphql/generated/operations'
import { StreamingProtocol, StreamingType } from '@/graphql/generated/schemas'

import { DeviceKeys, LiveKeys, SteamingTypeOptionI } from '../../types/types'
import { CameraFormDataTypeI, UpdateCameraInputsI } from '../../types/types'

const ipRegex = /^[A-Za-z0-9_$.+!*()-]*$/
const portRegex =
  /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/

const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/

export const getRTSPPath = (
  data: DeviceCameraEditQuery,
  streamingProtocol: string,
  path: string,
  ip: string,
  port: string
) => {
  return `${
    streamingProtocol || data?.device?.camera?.streamingProtocol
  }://{{username}}:{{password}}@${ip || data?.device?.camera?.rtspIp}:${
    port || data?.device?.camera?.rtspPort
  }/${path}`
}

export const validatePort = (value: string) => {
  if (!value) {
    return 'RTSP Port is required'
  } else if (!portRegex.test(value)) {
    return 'Enter a valid port'
  } else return true
}

export const validateIPOrHostname = (value: string, isRequired = true) => {
  if (!value && isRequired) {
    return 'RTSP IP/hostname is required'
  } else if (!ipRegex.test(value)) {
    return 'Enter a valid ip or hostname'
  } else return true
}

export const validateMac = (value: string) => {
  if (value.length > 0 && !macAddressRegex.test(value)) {
    return 'Enter a valid mac address'
  } else return true
}

export const getStreamingProtocolOption = (data: DeviceCameraEditQuery) => {
  return streamingProtocols?.find(
    (i) => i.value === data?.device?.camera?.streamingProtocol
  )
}

export const getStreamingTypeOption = (data: DeviceCameraEditQuery) => {
  return streamingTypes?.find(
    (i) => i.value === data?.device?.camera?.streamingType
  )
}

export const getCameraInputs = (
  id: string,
  values: CameraFormDataTypeI
): UpdateCameraInputsI => {
  if (shouldHandleRTSPFields(values?.streamingType?.value)) {
    return {
      id,
      externalId: values.externalId,
      rtspPath: values.rtspPath,
      rtspUsername: values.rtspUsername,
      rtspIp: values.rtspIp,
      rtspPort: values.rtspPort ? parseInt(values.rtspPort) : null,
      streamingProtocol: values.streamingProtocol?.value,
      streamingType: values.streamingType?.value,
      ...(values.rtspPassword !== DEFAULT_MASKED_PASSWORD && {
        rtspPassword: values.rtspPassword,
      }),
    }
  } else {
    return {
      id,
      externalId: values.externalId,
      streamingType: values.streamingType?.value,
      //Reset rtsp fields
      rtspPath: '',
      rtspUsername: '',
      rtspPassword: '',
      rtspIp: '',
      rtspPort: 554,
      streamingProtocol: StreamingProtocol.Rtsp,
    }
  }
}

export const streamingProtocols = [
  { value: StreamingProtocol.Rtsp, label: StreamingProtocol.Rtsp },
  { value: StreamingProtocol.Rtsps, label: StreamingProtocol.Rtsps },
]

export const streamingTypes = [
  { value: StreamingType.OnPremise, label: 'On Premise' },
  { value: StreamingType.VerkadaCloud, label: 'Verkada Cloud' },
  { value: StreamingType.AvaCloud, label: 'Ava Cloud' },
]

export const shouldHandleRTSPFields = (
  streamingType: StreamingType
): boolean => {
  return (
    streamingType === StreamingType.OnPremise ||
    streamingType === StreamingType.AvaCloud
  )
}

export const isRTSPFieldRequired = (option: SteamingTypeOptionI): boolean => {
  return option?.value === StreamingType.OnPremise
}

export const isLiveStreamingSetup = (
  cameraData: DeviceCameraEditQuery,
  isOrangePeel: boolean
): boolean => {
  const isRtspConfigured =
    cameraData?.device?.camera?.rtspPath &&
    cameraData?.device?.camera?.rtspUsername &&
    cameraData?.device?.camera?.streamingType === StreamingType.OnPremise
  const isVerkadaConfigured =
    cameraData?.device?.camera?.streamingType === StreamingType.VerkadaCloud
  const isAvaConfigured =
    cameraData?.device?.camera?.streamingType === StreamingType.AvaCloud

  return (
    (isRtspConfigured || isVerkadaConfigured || isAvaConfigured) && isOrangePeel
  )
}

export const isDeviceFieldsDirty = (
  dirtyFields: DeepMap<FieldValues, true>
): boolean => {
  return Object.keys(dirtyFields).find(
    (key: DeviceKeys) =>
      key === 'name' || key === 'floorId' || key === 'macAddress'
  )
    ? true
    : false
}

export const isLiveFieldsDirty = (
  dirtyFields: DeepMap<FieldValues, true>
): boolean => {
  return Object.keys(dirtyFields).find(
    (key: LiveKeys) =>
      key === 'externalId' ||
      key === 'streamingProtocol' ||
      key === 'streamingType' ||
      key === 'rtspPath' ||
      key === 'rtspUsername' ||
      key === 'rtspPassword' ||
      key === 'rtspIp' ||
      key === 'rtspPort'
  )
    ? true
    : false
}
